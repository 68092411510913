<template>
  <div class="business">
    <div class="business-all">
      <div class="title">
        <div @click="goBack" v-if="roleType == 1">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="title-item">营业收入</div>
      </div>
    </div>
    <div class="day-num">
      <div class="head">
        <p>当日营收（元）</p>
        <div class="head-flex">
          <div class="flex-left">{{ revenueObj.totalRevenue }}</div>
          <p class="flex-right" @click="handleLookData">查看历史收入></p>
        </div>
      </div>
    </div>

    <div class="business-pie">
      <div class="foot">
        <div class="foot-left">营收明细</div>
        <p class="foot-right">时段：00：00-23：59</p>
      </div>
      <div id="pie"></div>
    </div>
    <div class="business-line">
      <h3>近7天资金收入对比</h3>
      <div id="line"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { Toast } from "vant";
import Footer from "@/components/foot.vue";
export default {
  name: "business",
  components: {
    Footer
  },
  data() {
    return {
      list: [],
      shopId: "",
      roleType: 0,
      revenueObj: {},
      sevenDays: []
    };
  },
  mounted() {
    this.roleType = localStorage.roleType;
    this.shopId = this.$route.query.shopId || localStorage.shopId;
    
    this.handleFirstData();
    this.getTrend();
  },
  methods: {
    getTrend() {
      let _this = this;
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - 7);
      let endTime = this.parseTime(new Date()-24*60*60*1000, "{y}-{m}-{d}");
      // let  endTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let startTime = this.parseTime(new Date(myDate), "{y}-{m}-{d}");
      let params = {
        shopId: this.shopId,
        startTime: startTime,
        endTime:  endTime
      };
      _this.$API.getRevenueForSevenDays(params).then(function(res) {
        if (res.code == 200) {
          _this.sevenDays = res.data;
          _this.handleLineData();
        } else {
          Toast(res.msg);
        }
      });
    },
    handleFirstData() {
      let dataTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let _this = this;
      _this.$API
        .getRevenue({ shopId: this.shopId, date: dataTime })
        .then(function(res) {
          if (res.code == 200) {
            _this.revenueObj = res.data;
            _this.handlePieData();
          } else {
            Toast(res.msg);
          }
        });
    },
    // 环形图
    handlePieData() {
      //初始化
      let myChart = this.$echarts.init(document.getElementById("pie"));
      // 回执图表
      myChart.setOption({
        legend: {
          top: "bottom"
        },
        legend: { show: false },
        graphic: [
          //为环形图中间添加文字
          {
            type: "text",
            left: "center",
            top: "center",
            style: {
              textAlign: "center",
              fill: "#222222",
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: "营收明显",
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            labelLine: {
              show: true,
              normal:{  
                length:3,  // 改变标示线的长度
                lineStyle: {
                  color: "#ECE9EC"  // 改变标示线的颜色
                }
              },
            },
            label: {
              normal: {
                position: "outside", // 设置标签向外
                // formatter: "{b} \n\n {color|{c}} ", // 设置标签格式
                formatter: function(res){ // 设置标签格式
                  let  data = ''
                  let  arr = [
                    {
                      name: '网费消费',
                    },
                    {
                      name: '商品消费',
                    }
                  ]
                  for(let i in arr) {
                    if(arr[i].name == res.name)  {
                      data = `{rect|} ${res.name}  \n\n {color|${res.value}} `
                    } else {
                      data = `{rects|} ${res.name} \n\n {color|${res.value}} `

                    }

                  }
                  return data
                 
                },
                rich: {
                  color: {
                    color: "#FE1C1C"
                  },
                  rect:{
                    height: 8,
                    width: 8,
                    backgroundColor: "#2765F2",
                    borderRadius: 3,
                  },
                  rects:{
                    height: 8,
                    width: 8,
                    backgroundColor: "#5CD08C",
                    borderRadius: 3,
                  }
                }
              }
            },
            data: [
              {
                value: this.revenueObj.networkFee,
                name: "网费消费",
                itemStyle: { color: "#5CD08C" }
              },
              {
                value: this.revenueObj.sale,
                name: "商品消费",
                itemStyle: { color: "#2765F2" }
              }
            ]
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    // 折线图
    handleLineData() {
      let that = this;
      let valArr = [];
      let textArr = [];
      if (that.sevenDays.length != 0) {
        for (let i in that.sevenDays) {
          valArr.push(that.sevenDays[i].money);
          textArr.push(that.sevenDays[i].time);
        }
      }
      //初始化
      let myChart = that.$echarts.init(document.getElementById("line"));
      // 回执图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        color: ["#4DBB81", "#6D8EFA"],
        legend: { show: false },
        grid: {
          top: "6%",
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            }
          },
          axisLabel: {
            interval:0 ,
            fontSize: 12
          },

          axisTick: {
            show: false
          },
          type: "category",
          axisLine: { 
            onZero: false ,
            show: false
          },
          data: textArr
        },
        yAxis: {
          axisLine: { 
            onZero: false ,
            show: false
          },
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            },
          }
        },
        series: [
          {
            data: valArr,
            type: "line",
            smooth: true
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    goBack() {
      this.$router.push({ name: "Store" });
    },
    // 查看历史营收
    handleLookData() {
      this.$router.push({ name: "History", query: { type: 2 } });
    }
  }
};
</script>
<style lang="scss" scoped>
.business {
  padding-bottom: 120px;
  .business-all {
    padding: 30px;
    .title {
      display: flex;
      align-items: center;
      i {
        font-size: 30px;
      }
      .title-item{
        flex: 5;
        text-align: center;
        font-size: 38px;

      }
    }
  }
  .day-num {
    height: 300px;
    background: url("~@/assets/img/total/businessBg.png");
    background-size: 100% 100%;
    overflow: hidden;
    .head {
      color: #ffffff;
      margin: 60px 80px 100px 100px;
      > p {
        font-size: 24px;
      }
      .head-flex {
        display: flex;
        margin-top: 40px;
        .flex-left {
          font-size: 56px;
        }
        .flex-right {
          margin-left: auto;
          font-size: 26px;
          margin-top: 30px;
        }
      }
    }
  }
  .business-pie {
    padding: 0 35px;
    .foot {
      display: flex;
      margin-left: 4px;
      .foot-left {
        font-size: 28px;
        color: #000000;
      }
      .foot-right {
        color: #afb6cd;
        font-size: 24px;
        margin-left: auto;
        margin-top: 10px;
      }
    }
    #pie {
      width: 100%;
      height: 400px;
    }
  }
  .business-line {
    padding: 0 35px;
    margin-left: 4px;
    h3 {
      color: #000;
      font-size: 28px;
      margin: 35px 0;
    }
    #line {
      width: 100%;
      height: 360px;
    }
  }
}
</style>
